<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>Administration</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">Search By</p>
        <select v-model="inputMode">
          <option value="" disabled>Choose</option>
          <option value="username">Name</option>
          <option value="email">Email</option>
          <!-- <option value="mobileNo">연락처</option>
          <option value="address">주소</option> -->
        </select>
        <input type="text" v-model="searchWord" />
      </div>
      <!-- <div class="flex">
        <p class="bold">성별</p>
        <label>
          <input type="radio" v-model="gender" value="" checked />전체
        </label>
        <label> <input type="radio" v-model="gender" value="M" />남 </label>
        <label> <input type="radio" v-model="gender" value="F" />여 </label>
      </div> -->

      <div class="buttonWrap">
        <button class="basic" @click="search">Search</button>
        <button class="line" @click="reset">Reset</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">Administration</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 10%" />
          <col style="width: 25%" />
          <col style="width: 30%" />
          <col style="width: 25%" />
        </colgroup>
        <tr>
          <th>No.</th>
          <th>Title</th>
          <th>Name</th>
          <th>Email</th>
          <th>Member Since</th>
        </tr>
        <tr v-for="(data, i) in userList" :key="i">
          <td>
            <router-link :to="`/admin/userDetail?id=${data._id}`">
              {{ data.dataNum }}
            </router-link>
          </td>
          <td>{{ data.isAdmin == true ? "Admin" : "Member" }}</td>
          <td>
            <router-link :to="`/admin/userDetail?id=${data._id}`">
              {{ data.firstName }} {{ data.lastName }}
            </router-link>
          </td>
          <td>{{ data.email }}</td>
          <td>
            {{ moment(data.createdAt).format("YYYY.MM.DD") }}
          </td>
        </tr>
      </table>
      <NoData v-if="userList.length == 0" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserList } from "@/api/admin";
// import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  // mixins: [format],
  name: "UserManage",
  components: { NoData },
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      Page: 1,
      // year: "",
      // gender: "",
      // job: "",
      // isMarried: "",
      // loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
  },
  mounted() {
    // this.loading = true;
    this.getUserList();
  },
  methods: {
    // setYear() {
    //   let today = new Date();
    //   this.year = today.getFullYear();
    // },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.userList = [];
      this.total = 0;
      this.page = 1;
      this.getUserList();
    },

    search() {
      // if (this.searchWord && this.inputMode == "") {
      //   return alert("검색 조건을 선택을 주세요.");
      // } else if (this.inputMode && this.searchWord == "") {
      //   return alert("검색어를 입력해 주세요.");
      // }
      this.page = 1;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.page,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
      };
      fetchUserList(params).then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.userList = res.data.data;
            this.total = res.data.total;
            this.page = res.data.page;
          } else if (res.data.status == 409) {
            //this.$router.push("/");
            return alert(res.data.message);
          }
        } else {
          alert(res.data.message);
        }
      });
    },
  },
};
</script>
